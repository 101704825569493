import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj14 = () => {
  return (
    <Layout>
      <SEO title="FLAMES" description="Awesome python project" />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-14">
          <div className="py-1-heading">
            <h1>FLAMES</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/FLAMES"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              Just, Amazing game !<br />
              <br />
              Here,
              <br />
              F - Friends
              <br /> L - Love <br />A - Affection <br />M - Marrige
              <br /> E - Enemy <br />S - Sister
              <br />
              <br />
              It also involves famous mathematical problem, Josephus problem.
              <br />
              <br />
              All we have to do is, <br />
              Take a boy name and a girl game
              <br />
              That's it...
              <br />
              Further task is handled by python...
              <br />
              It's really fun !
              <br />
              <br />
              Let me tell you a secret ...I tried it...
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>String</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj14
